import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Text,
    Input,
    Link,
} from '@chakra-ui/react';
import { useBotContext } from '../../../../../contexts/BotContext';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { updatePineconeDatabase, fetchPineconeDatabase, updateDefaultDatabase } from '../../../../../Service';
import { Spinner } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';

import toast from 'react-hot-toast';

function Pinecone() {
    const token = window.localStorage.getItem('user-token');

    const { botId, dbType, updateDbType } = useBotContext();
    const [loading, updateLoader] = useState(false);

    const [formData, setFormData] = useState({
        envName: '',
        apiKey: '',
        indexName: '',
        host: '',
    });
    const bgColor = useColorModeValue("#F4F7FD", "navy.900");
    const textColor = useColorModeValue("navy.700", "white");

    const changeFormData = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    const updateDatabase = () => {
        updateLoader(true);
        updatePineconeDatabase({
            envName: formData.envName,
            apiKey: formData.apiKey,
            indexName: formData.indexName,
            host: formData.host,
        }, token, botId)
            .then(() => {
                toast.success("Pinecone database updated successfully")
                updateLoader(false);
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateLoader(false);
            });
    };

    const fetchDatabase = () => {
        updateLoader(true);
        fetchPineconeDatabase(token, botId)
            .then((response) => {
                setFormData({ ...response.data });
                updateLoader(false);
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateLoader(false);
            });
    };

    const setDefault = (id) => {
        updateLoader(true);
        updateDefaultDatabase(token, botId, {
            dbType: id,
        })
            .then((response) => {
                toast.success("Default database updated successfully")
                updateDbType(
                    response.data.dbType
                );
                updateLoader(false);
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateDbType(0);
                updateLoader(false);
            });
    };

    useEffect(() => {
        fetchDatabase(botId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId]);

    const LinkStyle = {
        textDecoration: 'none',
        color: '#007FC3',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    };

    return (
        <Box my={3}>
            <Box mb={8}>
                <Text color={textColor} fontSize="xl" fontWeight="bold">
                    Pinecone Settings
                </Text>
                <Text color={textColor}>Enter your Pinecone index details here</Text>
            </Box>
            <Input
                color={textColor}
                bg={bgColor}
                _focus={{ boxShadow: "none" }}
                style={{ borderRadius: "50px", height: '50px' }}
                mb={3}
                type="text"
                placeholder='Index Name'
                onChange={(e) => {
                    changeFormData('indexName', e.target.value);
                }}
                value={formData.indexName}
            />
            <Input
                color={textColor}
                bg={bgColor}
                _focus={{ boxShadow: "none" }}
                style={{ borderRadius: "50px", height: '50px', boxShadow: "none" }}
                mb={3}
                type="text"
                placeholder='Environment Name'
                onChange={(e) => {
                    changeFormData('envName', e.target.value);
                }}
                value={formData.envName}
            />
            <Input
                color={textColor}
                bg={bgColor}
                _focus={{ boxShadow: "none" }}
                style={{ borderRadius: "50px", height: '50px' }}
                mb={3}
                type="text"
                placeholder='API Key'
                onChange={(e) => {
                    changeFormData('apiKey', e.target.value);
                }}
                value={formData.apiKey}
            />
            <Input
                color={textColor}
                bg={bgColor}
                _focus={{ boxShadow: "none" }}
                style={{ borderRadius: "50px", height: '50px' }}
                mb={8}
                type="text"
                placeholder='Host'
                onChange={(e) => {
                    changeFormData('host', e.target.value);
                }}
                value={formData.host}
            />
            <Box>
                <Button
                    onClick={() => updateDatabase()}
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='20%'
                    h='50'
                    mb='24px'
                    mr={5}
                >
                    {
                        loading ? <Spinner color="white" /> : "Update"
                    }
                </Button>
                {dbType === 1 ? <Button
                        onClick={() => setDefault(0)}
                        fontSize='sm'
                        fontWeight='500'
                        w='20%'
                        h='50'
                        mb='24px'
                    >
                        {
                            loading ? <Spinner color="white" /> : "Remove as default"
                        }
                    </Button> : (
                    <Button
                        onClick={() => setDefault(1)}
                        fontSize='sm'
                        fontWeight='500'
                        w='20%'
                        h='50'
                        mb='24px'
                    >
                        {
                            loading ? <Spinner color="white" /> : "Set as default"
                        }
                    </Button>
                )}
            </Box>
            <Link href="https://docs.pinecone.io/docs/openai" target='_blank' rel="noreferrer" style={LinkStyle}>
                Read Documentation <FaLongArrowAltRight />
            </Link>
        </Box>
    );
}

export default Pinecone;
