import { Box } from "@chakra-ui/react";

const MessageBox = ({ key, message, children, ...rest }) => {
  return (
    <Box
      key={key}
      maxWidth="70%"
      height="auto"
      margin="8px"
      padding={message.is_voice || message.image_path || message.document_path ? "0px" : "12px"}
      border={message.is_voice || message.image_path || message.document_path ? "none" : "1px solid rgba(0,0,0,0.1)"}
      backgroundColor={
        message.isSent && !message?.is_voice
          ? "#fff"
          : message.isBot || message.isSent
          ? message?.is_voice || message.image_path || message.document_path
            ? "transparent"
            : "#412AFB"
          : "lightgrey"
      }
      color={message.isSent ? "#000" : message.isBot ? "#fff" : "#000"}
      borderRadius={
        message.isSent
          ? "12px 0 12px 12px"
          : message.isBot
          ? message?.is_voice || message.document_path
            ? "0px"
            : "0 12px 12px 12px"
          : "12px 12px 12px 0"
      }
      wordWrap="break-word"
      alignSelf={message.isSent ? "flex-end" : "flex-start"}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default MessageBox;
