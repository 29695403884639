import { Box, Stack, HStack, Text, Heading, Divider, List, ListIcon, ListItem, Button, useDisclosure } from "@chakra-ui/react";

import { ArrowForwardIcon, CheckCircleIcon } from "@chakra-ui/icons";
import PropTypes from "prop-types";
import PaymentModal from "./Modal";
import { useState } from "react";

const PricingBox = ({
  move,
  isSubscribed,
  currentPlan,
  popular,
  name,
  price,
  info = "",
  features = [],
  rollover,
  allowed_models,
  web_bots,
  discord_bots,
  api_bots,
  cw_control,
  ltm,
  auraDS,
  pinecone,
  storage_space,
  storage_characters,
  delete_inactive,
  onSubscribe,
  onDowngrade,
  founder,
  credits,
  n_bots,
  is_founder,
  is_free,
  is_current,
  ending_at,
  starting_at,
  handleCancelSubscription,
  buttonLoading,
  proratedAmount,
  calculateProratedAmount,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [handler, setHandler] = useState(null);
  const [handlerType, setHandlerType] = useState("cancel");
  const [planName, setPlanName] = useState("");
  const [planAmount, setPlanAmount] = useState(0);
  return (
    <Stack
      spacing={2}
      border="3px solid"
      borderColor={popular ? "brand.300" : founder ? "#2E4053" : "gray.300"}
      borderRadius="0.7rem"
      p={4}
      h="700px"
      w="320px"
      backgroundColor="white"
      position="relative"
    >
      {popular && (
        <Box
          position="absolute"
          top="0"
          right="0"
          backgroundColor="brand.300"
          color="white"
          paddingX={2}
          paddingY={1}
          borderRadius="0 0 0 0.7rem"
          fontSize="0.8rem"
        >
          POPULAR
        </Box>
      )}
      {founder && (
        <Box
          position="absolute"
          top="0"
          right="0"
          backgroundColor="#2E4053"
          color="white"
          paddingX={2}
          paddingY={1}
          borderRadius="0 0 0 0.7rem"
          fontSize="0.8rem"
        >
          LIFETIME
        </Box>
      )}
      <Text textTransform="uppercase">{name}</Text>
      <HStack>
        <Heading>{"$" + price ?? "Free"}</Heading>
        {price && (
          <Box as="span" color="gray.600" fontSize="sm">
            {founder ? " / One Time" : " / Per Month"}
          </Box>
        )}
      </HStack>
      <Divider borderColor="blackAlpha.500"/>
      <List flex="1" fontSize={'16px'}>
        {/* List all features of a particular plan */}
        {features.map((feat) => (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            {feat}
          </ListItem>
        ))}
        {/* {rollover && (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            {rollover}% credits rollover
          </ListItem>
        )} */}
        <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
          <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
          {!allowed_models ? "gpt3.5" : "All models"}
        </ListItem>
        {web_bots && (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            Website Chatbots
          </ListItem>
        )}
        {discord_bots && (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            Discord bots
          </ListItem>
        )}
        {api_bots && (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            API bots
          </ListItem>
        )}
        {cw_control && (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            Context window control
          </ListItem>
        )}
        <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
          <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
          {ltm} Long term memory
        </ListItem>
        {auraDS && (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            Super Intelligence (Coming Soon)
          </ListItem>
        )}
        {pinecone && (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            Pinecone
          </ListItem>
        )}
        <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
          <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
          {storage_space >= 1e3 ? Math.floor(storage_space / 1e3) + 'GB' : Math.floor(storage_space) + 'MB'} Storage
        </ListItem>
        <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
          <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
          {storage_characters > 1e6 ? Math.floor(storage_characters / 1e6) + 'M' : Math.floor(storage_characters / 1e3) + 'K'} Characters
        </ListItem>
        {delete_inactive && (
          <ListItem key={Math.random()} mb="1" display="flex" alignItems="center">
            <ListIcon as={CheckCircleIcon} color="gray.400" fontSize='xs' />
            Inactive bots deleted after 30 days
          </ListItem>
        )}
      </List>
      <Box>
        {ending_at && (
          <Text fontSize="xs" textAlign="center">
            Ending on {new Date(ending_at).toLocaleDateString(undefined , { year: "numeric", month: "long", day: "numeric" })}
          </Text>
        )}

        {starting_at && (
          <Text fontSize="xs" textAlign="center">
            Starting on {new Date(starting_at).toLocaleDateString(undefined , { year: "numeric", month: "long", day: "numeric" })}
          </Text>
        )}
        <Button
          backgroundColor={founder ? "#2E4053" : "brand.300"}
          size="sm"
          width="100%"
          rightIcon={<ArrowForwardIcon />}
          borderRadius={0}
          display="flex"
          justifyContent="space-between"
          _hover={{
            backgroundColor: popular ? "brand.500" : "gray.300",
          }}
          color="white"
          onClick={() => {
            onOpen();
            setPlanName(name);
            setPlanAmount(price);
            if (!is_current) {
              if (move) {
                setHandlerType("downgrade");
                setHandler(() => onDowngrade);
              } else {
                setHandlerType(isSubscribed && !founder && !is_free ? "upgrade" : "buy");
                setHandler(() => onSubscribe);
              }
            }
          }}
          {...(is_current || starting_at || is_founder ? { disabled: true } : {})}
        >
          {is_current || (founder && is_founder)
            ? "Current Plan"
            : move
            ? starting_at
              ? "Upcoming Plan"
              : "Downgrade"
            : isSubscribed && !is_free
            ? "Upgrade"
            : "Buy plan"}
        </Button>
        {(starting_at || (is_current && !ending_at)) && (
          <Button
            mt={2}
            backgroundColor={founder ? "#2E4053" : "brand.300"}
            size="sm"
            width="100%"
            borderRadius={0}
            display="flex"
            justifyContent="center"
            _hover={{
              backgroundColor: popular ? "brand.500" : "gray.300",
            }}
            color="white"
            onClick={() => {
              onOpen();
              setHandlerType("cancel");
              setHandler(() => handleCancelSubscription());
            }}
          >
            Cancel Subscription
          </Button>
        )}
        <Text fontSize="xs">{info}</Text>
      </Box>
      <PaymentModal
        isOpen={isOpen}
        onClose={onClose}
        handler={handler}
        loader={buttonLoading}
        handlerType={handlerType}
        planName={planName}
        planAmount={planAmount}
        planCredits={credits}
        planBots={n_bots}
        currentPlan={currentPlan}
        proratedAmount={proratedAmount}
        calculateProratedAmount={calculateProratedAmount}
      />
    </Stack>
  );
};
PricingBox.propTypes = {
  name: PropTypes.string.isRequired,
  popular: PropTypes.bool,
  price: PropTypes.string,
  info: PropTypes.string,
  features: PropTypes.array.isRequired,
};

export default PricingBox;
