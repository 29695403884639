import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListIcon,
  ListItem,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { connectAndFetchVoices } from "../../../../Service";
import toast from "react-hot-toast";
import { useBotContext } from "../../../../contexts/BotContext";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const VoiceSettings = () => {
  const token = window.localStorage.getItem("user-token");
  const [voiceList, setVoiceList] = useState([]);
  const [elabsConnector, setElabsConnector] = useState(false);
  const { botId, elabsApiKey, updateElabsApiKey, elabsVoice, updateElabsVoice } = useBotContext();
  const [tempApiKey, setTempApiKey] = useState("");
  const [tempSelectedVoice, setTempSelectedVoice] = useState("");
  const [show, setShow] = useState(false);

  const handleConnection = async (key = null, voice = null, ok = false) => {
    setElabsConnector(true);
    await connectAndFetchVoices(token, { xi_api_key: key, voice_id: voice, botId })
      .then((response) => {
        const data = response.data.voices;
        setVoiceList(
          data?.map((item) => {
            return {
              voice_id: item[0][1],
              name: item[1][1],
            };
          })
        );

        updateElabsApiKey(key);
        updateElabsVoice(voice);
        if (key !== elabsApiKey || voice !== elabsVoice) {
          toast.success("Settings updated successfully");
        }
      })
      .catch((error) => {
        toast.error("Invalid API key");
        updateElabsApiKey(null);
        updateElabsVoice(null);
        setTempSelectedVoice("");
        setVoiceList([]);
      })
      .finally(() => setElabsConnector(false));
  };

  useEffect(() => {
    if (elabsApiKey) {
      setTempApiKey(elabsApiKey);
      setTempSelectedVoice(elabsVoice);
      handleConnection(elabsApiKey, elabsVoice, true);
    }
  }, []);

  return (
    <>
      <Flex direction={"column"} gap={"10px"}>
        <Text fontSize={"30px"} fontWeight={600}>
          IIElevenLabs
        </Text>
        <Text>Integrate Elevenlabs voices for your bot and improve your customer experience!</Text>
        <List spacing={3}>
          <ListItem>
            <ListIcon color="green.500" />
            Create custom voices for your bot.
          </ListItem>
          <ListItem>
            <ListIcon color="green.500" />
            Available via API.
          </ListItem>
          <ListItem>
            <ListIcon color="green.500" />
            Test your voices in the chat tab before deploying.
          </ListItem>
        </List>
      </Flex>
      <FormControl mt={10} maxWidth={"700px"}>
        <Flex direction={"column"}>
          <FormLabel>ElevenLabs API key</FormLabel>
          <InputGroup>
            <Input
              name="elabs_api"
              required
              _focus={{ boxShadow: "none" }}
              bg={"#F4F7FD"}
              style={{ borderRadius: "50px", height: "50px" }}
              mr={2}
              type={show ? "text" : "password"}
              placeholder="Enter your Elevenlabs API key"
              value={tempApiKey}
              onChange={(e) => setTempApiKey(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                {!show ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>

          {voiceList.length > 0 && (
            <>
              <FormLabel mt={5}>Select from availabe voices</FormLabel>
              <Select
                defaultValue={tempSelectedVoice}
                maxWidth={"700px"}
                placeholder="Select a voice"
                onChange={(e) => {
                  setTempSelectedVoice(e.target.value);
                }}
              >
                {voiceList.map((voice, index) => {
                  return (
                    <option key={index} value={voice?.voice_id}>
                      {voice?.name}
                    </option>
                  );
                })}
              </Select>
            </>
          )}

          <Button
            ml={"auto"}
            mt={5}
            variant="brand"
            fontSize="sm"
            fontWeight="500"
            w="20%"
            h="50"
            mb="24px"
            onClick={() => handleConnection(tempApiKey, tempSelectedVoice)}
          >
            {elabsConnector ? <Spinner color="white" /> : "Save"}
          </Button>
        </Flex>
      </FormControl>
    </>
  );
};

export default VoiceSettings;
