import { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const useModalContext = () => {
    return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
    const [isPlansModalOpen, setIsPlansModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const updateLoader = (state) => setIsLoading(state)

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const updateAccountModal = (state) => setIsAccountModalOpen(state);
    const updatePlansModal = (state) => setIsPlansModalOpen(state);

    return (
        <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, isLoading, updateLoader, isAccountModalOpen, updateAccountModal, isPlansModalOpen, updatePlansModal }}>
            {children}
        </ModalContext.Provider>
    );
};
