import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Text,
    Textarea,
    Button,
} from '@chakra-ui/react';
import { useBotContext } from '../../../../contexts/BotContext';
import { useUserContext } from '../../../../contexts/UserContext';
import { useColorModeValue } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
  } from '@chakra-ui/react'

import { updateKnowledge, deleteKnowledge, fetchKnowledge } from '../../../../Service';
import toast from 'react-hot-toast';

function Knowledge() {
    const token = window.localStorage.getItem('user-token');
    const [loading, setLoading] = useState(false); // eslint-disable-line no-unused-vars
    const { updateTokens } = useUserContext();
    const bgColor = useColorModeValue("#F4F7FD", "navy.900");
    const textColor = useColorModeValue("navy.700", "white");
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [iknowledge, setIknowledge] = useState()
    const cancelRef = useRef()

    const { botId } = useBotContext();

    const [knowledge, setKnowledge] = useState('');
    const { isAdmin } = useUserContext();

    const updateTheKnowledge = () => {
        setLoading(true);
        updateKnowledge({
            knowledge
        }, token, botId)
            .then((response) => {
                toast.success("Knowledge updated successfully");
                updateTokens(response.data.tokens);
                setKnowledge(response.data.knowledge);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                setLoading(false);
            });
    };

    const deleteTheKnowledge = () => {
        onClose()
        setLoading(true);
        deleteKnowledge(botId, token)
            .then((response) => {
                toast.success("Knowledge cleared successfully");
                updateTokens(response.data.tokens);
                setKnowledge(response.data.knowledge);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                setLoading(false);
            });
    };

    const fetchTheKnowledge = () => {
        setLoading(true);
        fetchKnowledge(token, botId)
            .then((response) => {
                setKnowledge(response.data.knowledge);
                setIknowledge(response.data.knowledge_size)
                setLoading(false);
            })
            .catch(() => {
                toast.error("Something went wrong");
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchTheKnowledge();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId]);

    return (
        <>
            <Box my={3}>
                <Text fontSize="28px" fontWeight="bold" marginBottom="10px" color={textColor}>
                    Train your Model
                </Text>
                <Text marginBottom="20px" color={textColor}>This information is stored in your database in the form of long-term memory.</Text>
                {isAdmin ? (
                    <Textarea
                        placeholder="Enter knowledge Here..."
                        value={knowledge}
                        onChange={(e) => setKnowledge(e.target.value)}
                        borderRadius="8px"
                        margin="8px 0"
                        padding="15px 10px"
                        minHeight="200px"
                        width="100%"
                        border="1px solid rgba(0,0,0,0.1)"
                        outline="none"
                        boxShadow="none"
                        background={bgColor}
                    />
                ) : (
                    <Textarea
                        maxLength={iknowledge}
                        placeholder="Enter knowledge Here..."
                        value={knowledge}
                        onChange={(e) => setKnowledge(e.target.value)}
                        borderRadius="8px"
                        margin="8px 0"
                        padding="15px 10px"
                        minHeight="200px"
                        width="100%"
                        border="1px solid rgba(0,0,0,0.1)"
                        outline="none"
                        boxShadow="none"
                        background={bgColor}
                    />
                )}
                {isAdmin ? <Text fontSize="12px" textAlign="right" color={textColor}>You are an admin!</Text> : <Text fontSize="12px" textAlign="right">Character Limit: {knowledge?.length}/{iknowledge}</Text>}
                <div style={{ display: 'flex', gap: '20px' }}>
                    <Button
                        fontSize='sm'
                        variant='brand'
                        fontWeight='500'
                        w='20%'
                        h='50'
                        mb='24px'
                        onClick={() => updateTheKnowledge()}
                    >
                        {
                            loading ? <Spinner color="white" /> : "Update"
                        }
                    </Button>
                    <Button
                        onClick={() => onOpen()}
                        fontSize='sm'
                        fontWeight='500'
                        w='20%'
                        h='50'
                        mb='24px'
                    >
                        Clear
                    </Button>
                </div>
            </Box>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Clear Knowledge</AlertDialogHeader>
                    <AlertDialogCloseButton _focus={{boxShadow: "none"}} />
                    <AlertDialogBody>
                        Are you sure you want to clear the knowledge?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose} _focus={{boxShadow: "none"}}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={() => deleteTheKnowledge()} _focus={{boxShadow: "none"}}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
}

export default Knowledge;
