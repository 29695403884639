import { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const useUserContext = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [tokens, setTokens] = useState(0);
    const [planName, setPlanName] = useState("");
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [planAmount, setPlanAmount] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [status, setStatus] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [contextSize, setContextSize] = useState(0);
    const [iknowledge, setKnowledge] = useState(0);
    const [founder, setFounder] = useState(false);
    const [free, setFree] = useState(false);
    const [n_bots, setN_bots] = useState(0);
    const [pId, setPId] = useState('');

    const updateTokens = (tokens) => setTokens(tokens);
    const updateIsSubscribed = (isSubscribed) => setIsSubscribed(isSubscribed);
    const updatePlanName = (planName) => setPlanName(planName);
    const updatePlanAmount = (planAmount) => setPlanAmount(planAmount);
    const updateStartDate = (startDate) => setStartDate(startDate);
    const updateEndDate = (endDate) => setEndDate(endDate);
    const updateStatus = (status) => setStatus(status);
    const updateIsAdmin = (isAdmin) => setIsAdmin(isAdmin);
    const updateContextSize = (contextSize) => setContextSize(contextSize);
    const updateKnowledge = (knowledge) => setKnowledge(knowledge);
    const updateFounder = (founder) => setFounder(founder);
    const updateN_bots = (n_bots) => setN_bots(n_bots);
    const updateFree = (free) => setFree(free);
    const updatePId = (pId) => setPId(pId);

    return (
        <UserContext.Provider value={{
            tokens,
            updateTokens,
            isSubscribed,
            updateIsSubscribed,
            planName,
            updatePlanName,
            planAmount,
            updatePlanAmount,
            startDate,
            updateStartDate,
            endDate,
            updateEndDate,
            status,
            updateStatus,
            isAdmin,
            updateIsAdmin,
            contextSize,
            updateContextSize,
            iknowledge,
            updateKnowledge,
            founder,
            updateFounder,
            n_bots,
            updateN_bots,
            free,
            updateFree,
            pId,
            updatePId
        }}>
            {children}
        </UserContext.Provider>
    );
};
