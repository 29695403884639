import React, { useState, useEffect } from 'react';
import {
    Box,
    Text,
    Textarea,
    Button,
} from '@chakra-ui/react';
import { useBotContext } from '../../../../contexts/BotContext';
import { useUserContext } from '../../../../contexts/UserContext';
import { useColorModeValue } from '@chakra-ui/react';
import { updateContext, fetchContext } from '../../../../Service';
import { Spinner } from '@chakra-ui/react';
import toast from 'react-hot-toast';

function Instructions() {
    const token = window.localStorage.getItem('user-token');
    const { botId } = useBotContext();
    const [instructions, setInstructions] = useState('');
    const { isAdmin } = useUserContext();
    const bgColor = useColorModeValue("#F4F7FD", "navy.900");
    const textColor = useColorModeValue("navy.700", "white");
    const [loading, setLoading] = useState(false);
    const [contextSize, setContextSize] = useState(0);

    const updateInstructions = () => {
        setLoading(true)
        updateContext({
            context: instructions,
        }, token, botId)
            .then(() => {
                toast.success("Instructions updated successfully")
                setLoading(false)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                setLoading(false)
            });
    };

    const fetchBotContext = () => {
        setLoading(true)
        fetchContext(token, botId)
            .then((response) => {
                setInstructions(response.data.context);
                setContextSize(response.data.context_window / 2);
                setLoading(false)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchBotContext();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId]);

    return (
        <>
            <Box my={3}>
                <Text fontSize="28px" fontWeight="bold" marginBottom="10px" color={textColor}>
                    Personality, Context, and Instructions
                </Text>
                <Text marginBottom="20px" color={textColor}>
                    Store your instructions for your bot here, including personality
                    information, context about what they will be doing, and any special
                    instructions you have for them.
                </Text>
                {isAdmin ? (
                    <Textarea
                        placeholder="Enter instructions Here..."
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                        borderRadius="8px"
                        margin="8px 0"
                        padding="15px 10px"
                        minHeight="200px"
                        width="100%"
                        border="1px solid rgba(0,0,0,0.1)"
                        outline="none"
                        boxShadow="none"
                        background={bgColor}
                    />
                ) : (
                    <Textarea
                        maxLength={contextSize}
                        placeholder="Enter instructions Here..."
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                        borderRadius="8px"
                        margin="8px 0"
                        padding="15px 10px"
                        minHeight="200px"
                        width="100%"
                        border="1px solid rgba(0,0,0,0.1)"
                        outline="none"
                        boxShadow="none"
                        background={bgColor}
                    />
                )}
                {isAdmin ? (
                    <Text fontSize="12px" textAlign="right">
                        You are an admin!
                    </Text>
                ) : (
                    <Text fontSize="12px" textAlign="right">
                        Character Limit: {instructions?.length}/{contextSize}
                    </Text>
                )}
                <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='20%'
                    h='50'
                    mb='24px'
                    onClick={() => updateInstructions(botId)}
                >
                    {
                        loading ? <Spinner color="white" /> : "Update"
                    }
                </Button>
            </Box>
        </>
    );
}

export default Instructions;
