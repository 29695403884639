import React, { useState } from "react";

import Card from "../../../../components/card/Card";
import Chatbox from "./Chatbox";
import Instructions from "./Instructions";
import Knowledge from "./Knowledge";
import FilesKnowledge from "./FilesKnowledge";
import Pinecone from "./Databases/Pinecone";
import Aura from "./Databases/AuraDS";
import Styles from "./Styles";
import Integrations from "./Integrations";
import Contact from "./Contact";
import URLKnowledge from "./URLKnowledge";
import GoogleSuiteVersionTwo from "./GoogleSuiteVtwo";
import Notifications from "./Notifications";

import { Flex, Box } from "@chakra-ui/react";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ImageUploader from "./ImageUploader";
import Gallery from "./Gallery";
import VoiceSettings from "./VoiceSettings";
import ConversationHistory from "./ConversationHistory";
import Analytics from "./Analytics.jsx";

export default function BotConfigrations() {
  const [counter, setCounter] = useState(0);

  return (
    <Card p="20px">
      <Flex
        mb="20px"
        mt="20px"
        style={{ width: "100%" }}
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }}
      >
        <Tabs variant="soft-rounded" colorScheme="purple" width="100%">
          <TabList>
            <Tab _focus={{ boxShadow: "none" }} width="16.66%">
              Chatbot
            </Tab>
            <Tab _focus={{ boxShadow: "none" }} width="16.66%">
              Analytics
            </Tab>
            <Tab _focus={{ boxShadow: "none" }} width="16.66%">
              Settings
            </Tab>
            <Tab _focus={{ boxShadow: "none" }} width="16.66%">
              Images
            </Tab>
            <Tab _focus={{ boxShadow: "none" }} width="16.66%">
              Memory
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Chatbox />
            </TabPanel>
            <TabPanel>
              <Analytics />
            </TabPanel>
            <TabPanel>
              <Box my={3}>
                <Tabs variant="soft-rounded" colorScheme="purple" width="100%">
                  <TabList>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Styles
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Integrations
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Others
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Voice
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Notifications
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Styles />
                    </TabPanel>
                    <TabPanel>
                      <Integrations />
                    </TabPanel>
                    <TabPanel>
                      <Contact />
                    </TabPanel>
                    <TabPanel>
                      <VoiceSettings />
                    </TabPanel>
                    <TabPanel>
                      <Notifications />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box my={3}>
                <Tabs variant="soft-rounded" colorScheme="purple" width="100%">
                  <TabList>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Upload
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Gallery
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <ImageUploader setCounter={setCounter} />
                    </TabPanel>
                    <TabPanel>
                      <Gallery counter={counter} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box my={3}>
                <Tabs variant="soft-rounded" colorScheme="purple" width="100%">
                  <TabList>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Pinecone
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      AuraDS
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Conversations
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Instructions
                    </Tab>
                    <Tab _focus={{ boxShadow: "none" }} width="20%">
                      Knowledge
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Pinecone />
                    </TabPanel>
                    <TabPanel>
                      <Aura />
                    </TabPanel>
                    <TabPanel>
                      <ConversationHistory />
                    </TabPanel>
                    <TabPanel>
                      <Instructions />
                    </TabPanel>
                    <TabPanel>
                      <Box my={3}>
                        <Tabs variant="soft-rounded" colorScheme="purple" width="100%">
                          <TabList>
                            <Tab _focus={{ boxShadow: "none" }} width="20%">
                              Manually
                            </Tab>
                            <Tab _focus={{ boxShadow: "none" }} width="20%">
                              Files
                            </Tab>
                            <Tab _focus={{ boxShadow: "none" }} width="20%">
                              URL
                            </Tab>
                            <Tab _focus={{ boxShadow: "none" }} width="20%">
                              Google Suite
                            </Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>
                              <Knowledge />
                            </TabPanel>
                            <TabPanel>
                              <FilesKnowledge />
                            </TabPanel>
                            <TabPanel>
                              <URLKnowledge />
                            </TabPanel>
                            <TabPanel>
                              <GoogleSuiteVersionTwo />
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Card>
  );
}
