import React, { useState, useRef } from "react";
import { IoStopSharp } from "react-icons/io5";
import { MdKeyboardVoice } from "react-icons/md";

const AudioRecorder = ({ sendMessage }) => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
        sendMessage(audioBlob);
        audioChunksRef.current = [];
      };

      audioChunksRef.current = [];

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      console.error("Failed to start recording:", err);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };
  return (
    <div style={{ position: "absolute", right: "18px", insetY: "0px", fontSize: "23px", color: "#412afb" }}>
      <button onClick={isRecording ? stopRecording : startRecording}> {isRecording ? <IoStopSharp /> : <MdKeyboardVoice />}</button>
    </div>
  );
};

export default AudioRecorder;
