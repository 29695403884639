import React, { useState, useRef, useEffect } from "react";
import { Box, Text, Input, Flex, Switch, Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import { useBotContext } from "../../../../contexts/BotContext";
import { useUserContext } from "../../../../contexts/UserContext";
import { chat, clearChat, getChats } from "../../../../Service";
import { useColorModeValue } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import toast from "react-hot-toast";
import PdfDocumentCard from "../../../../components/document/PdfDocument";
import OtherDocumentCard from "../../../../components/document/OtherDocument";
import VoiceCard from "../../../../components/voice/VoiceCard";
import ImageCard from "../../../../components/image/ImageCard";
import TextCard from "../../../../components/text/TextCard";
import MessageBox from "../../../../components/box/MessageBox";
import AudioRecorder from "./AudioRecorder";
import { v4 as uuidv4 } from "uuid";
import { ThreeDots } from "react-loader-spinner";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

function Chatbox() {
  const { botId, elabsApiKey, elabsVoice } = useBotContext();
  const { updateTokens } = useUserContext();

  const [loading, setLoading] = useState(false);
  const token = window.localStorage.getItem("user-token");
  const inputRef = useRef(null);
  const chatboxRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const bgColor = useColorModeValue("#F4F7FD", "navy.900");
  const textColor = useColorModeValue("navy.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [voiceEnabled, setVoiceEnabled] = useState(false);
  const [imageEnabled, setImageEnabled] = useState(false);
  const [documentEnabled, setDocumentEnabled] = useState(false);
  const [typing, setTyping] = useState(false);
  const cancelRef = useRef();

  const sendMessage = (audioBlob = null) => {
    const audioURL = audioBlob ? URL.createObjectURL(audioBlob) : null;
    const message = inputRef.current.value;
    if ((message === "" || message === undefined) && audioBlob == null) return;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, isSent: true, is_voice: audioBlob != null, voice_path: audioURL, autoplay: false },
    ]);
    setTyping(true);
    if (message) {
      inputRef.current.value = "";
    }

    const formData = new FormData();
    const payload = {
      api_key: "admin",
      bot_id: botId,
      message: message || "Audio file",
      is_document: documentEnabled, //Document
      is_voice: voiceEnabled,
      image_request: imageEnabled,
      username: "admin",
      user_id: "afacafq3f1sf3",
    };

    if (audioBlob) {
      formData.append("audio", audioBlob, `${uuidv4()}.wav`);
    }
    formData.append("data", JSON.stringify(payload));

    chat(formData, token)
      .then((response) => {
        updateTokens(response.data.tokens);

        const receviedSetofMessages = [
          {
            text: response.data.message,
            isBot: true,
            is_voice: response?.data?.voice_path ? true : false,
            voice_path: response.data.voice_path,
            autoplay: true,
          },
        ];

        if (response.data.document_path && documentEnabled) {
          receviedSetofMessages.push({
            text: response.data.message,
            isBot: true,
            is_voice: false,
            document_path: response.data.document_path,
          });
        }

        if (response.data?.image_path && imageEnabled) {
          receviedSetofMessages.push({
            text: response.data.message,
            isBot: true,
            is_voice: false,
            image_path: response.data.image_path,
          });
        }
        receviedSetofMessages.reverse();
        setMessages((prevMessages) => [...prevMessages, ...receviedSetofMessages]);
        setTyping(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error("Internal server error");
        }
        setTyping(false);
      });
  };

  const clearBotHistory = () => {
    setLoading(true);
    clearChat(botId, "afacafq3f1sf3", token)
      .then((res) => {
        toast.success("Chat history cleared successfully");
        setMessages([]);
        setLoading(false);
      })
      .catch(() => {
        toast.error("Chat history could not be cleared");
        setLoading(false);
      });
  };

  const fetchBotHistory = (botId) => {
    setLoading(true);
    getChats(botId, "afacafq3f1sf3", token)
      .then((response) => {
        setMessages(
          response.data.history.map((message) => ({
            text: message.text,
            isBot: message.is_bot,
            isSent: !message.is_bot,
            is_voice: message.is_voice,
            voice_path: message.voice_path,
            image_path: message.image_path,
            document_path: message.document_path,
          }))
        );
        setLoading(false);
      })
      .catch(() => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBotHistory(botId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => chatboxRef.current?.scrollIntoView({ inline: "center", behavior: "smooth" }), 777);
    if (chatboxRef.current) {
      chatboxRef.current.scrollTo({
        top: chatboxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, botId]);

  return (
    <>
      <Flex alignItems={"center"} justifyContent={"space-between"} marginBottom={"20px"}>
        <Text fontSize="28px" fontWeight="bold" color={textColor}>
          Test your bot here
        </Text>
        <Menu closeOnSelect={false}>
          <MenuButton borderRadius="4px" as={IconButton} aria-label="Options" icon={<HamburgerIcon />} variant="outline" />
          <MenuList>
            <MenuItem display={"flex"} alignItems="center" gap="10px">
              <Switch disabled={!elabsApiKey | !elabsVoice} isChecked={voiceEnabled} onChange={() => setVoiceEnabled(!voiceEnabled)} />
              <Text>Enable voice chat</Text>
            </MenuItem>
            <MenuItem display={"flex"} alignItems="center" gap="10px">
              <Switch isChecked={imageEnabled} onChange={() => setImageEnabled(!imageEnabled)} />
              <Text>Request for images?</Text>
            </MenuItem>
            <MenuItem display={"flex"} alignItems="center" gap="10px">
              <Switch isChecked={documentEnabled} onChange={() => setDocumentEnabled(!documentEnabled)} />
              <Text>Request for documents?</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        my={3}
        bg={bgColor}
        borderRadius="10px"
        resize="vertical"
        overflow="auto"
      >
        <Box ref={chatboxRef} flexGrow={1} height="calc(100vh - 500px)" padding="30px" display="flex" flexDirection="column" overflowY="auto">
          {messages.map((message, index) => (
            <MessageBox key={index} message={message}>
              {message?.document_path ? (
                message.document_path.endsWith(".pdf") ? (
                  <PdfDocumentCard message={message} />
                ) : (
                  <OtherDocumentCard message={message} />
                )
              ) : message?.image_path ? (
                <ImageCard message={message} />
              ) : !message?.is_voice ? (
                <TextCard message={message} />
              ) : (
                <VoiceCard message={message} />
              )}
            </MessageBox>
          ))}

          {typing && (
            <Box maxWidth="70%" height="auto" margin="8px">
              <ThreeDots
                visible={true}
                height="50"
                width="50"
                color="#412AFB"
                radius="7"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          )}
        </Box>
        <div className="send__message__container">
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                paddingBottom: "10px",
              }}
            >
              <Spinner color="blue.500" size="md" mr={3} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                paddingBottom: "10px",
              }}
            >
              <Box width="80%" position="relative" style={{ display: "flex", alignItems: "center" }}>
                <Input
                  disabled={typing}
                  name="chatbox_input"
                  _focus={{ boxShadow: "none" }}
                  ref={inputRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                  className="send__message__input"
                  type="text"
                  placeholder="How may I help you?"
                  color={textColor}
                  style={{
                    paddingRight: "50px",
                    height: "50px",
                    width: "100%",
                    borderRadius: "50px",
                  }}
                />
                {!typing && <AudioRecorder sendMessage={sendMessage} />}
              </Box>
            </div>
          )}
        </div>
      </Box>

      <Text
        color={textColor}
        cursor="pointer"
        width="fit-content"
        style={{ fontSize: "13px" }}
        onClick={() => {
          onOpen();
        }}
      >
        Click here to delete the chat history
      </Text>

      <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Clear history</AlertDialogHeader>
          <AlertDialogCloseButton _focus={{ boxShadow: "none" }} />
          <AlertDialogBody>Are you sure you want to clear the chat history?</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
              }}
              _focus={{ boxShadow: "none" }}
            >
              No
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                clearBotHistory();
                onClose();
              }}
              _focus={{ boxShadow: "none" }}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default Chatbox;
