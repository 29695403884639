import React, { useRef, useState, useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { getBots, createTheBot, generateSession } from "../../../Service";
import { useBotContext } from "../../../contexts/BotContext";

import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { RxDiscordLogo } from "react-icons/rx";
import { AiOutlineApi } from "react-icons/ai";
import LinkModal from "../../../components/links/modal";
// import { FaSlack } from "react-icons/fa";

import toast from "react-hot-toast";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Input,
  GridItem,
} from "@chakra-ui/react";

// Chakra imports
import { Box, Flex, Grid, Text, Button, useColorModeValue, SimpleGrid } from "@chakra-ui/react";

import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerFooter, Icon } from "@chakra-ui/react";

// Custom components
import Bot from "../../../components/card/Bot";
import BotConfigrations from "./Components/BotConfigrations";
import { Spinner } from "@chakra-ui/react";

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const day = date.getDate();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedDate = `${day} ${month} ${year}, ${hours}:${minutes.toString().padStart(2, "0")}${ampm}`;

  return formattedDate;
}

export default function Dashboard() {
  const {
    botName,
    botId,
    updateBotName,
    updateBotColor,
    updateBotId,
    updateDbType,
    updateBotType,
    updateIsRunning,
    dbType,
    updateElabsApiKey,
    updateElabsVoice,
  } = useBotContext();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("#F4F7FD", "navy.900");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modal = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [loader, updateLoader] = useState(false);
  const [bots, setBots] = useState([]);
  const [bottName, setBotName] = useState("");
  const btnRef = useRef();
  const [selectedBot, setSelectedBot] = useState(0);

  const handleBotList = () => {
    const token = window.localStorage.getItem("user-token");
    setLoading(true);
    getBots(token)
      .then((response) => {
        setBots(response.data.bots);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        // setLoading(false)
      });
  };

  const updateBot = (bot) => {
    updateBotName(bot.name);
    updateBotColor(bot.color);
    updateBotId(bot.botId);
    updateDbType(bot.dbType);
    updateBotType(bot.botType);
    updateIsRunning(bot.is_running);
    updateElabsApiKey(bot.elabs_api_key);
    updateElabsVoice(bot.elabs_voice_id);
  };

  const createBot = () => {
    const token = window.localStorage.getItem("user-token");
    if (bottName === "") {
      toast.error("Please enter bot name");
      return;
    }
    // if (selectedBot === 0) {
    //   toast.error('Please select bot type')
    //   return
    // }
    updateLoader(true);
    createTheBot(token, {
      name: bottName,
    })
      .then((response) => {
        toast.success(response.data.message);
        setBotName("");
        setSelectedBot(0);
        modal.onClose();
        updateLoader(false);
        handleBotList();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateLoader(false);
      });
  };

  const [shareLink, setShareLink] = useState("");
  const [showLink, setShowLink] = useState(false);
  const token = window.localStorage.getItem("user-token");
  const [generatingLink, setGeneratingLink] = useState(false);

  const generateNewSession = () => {
    setGeneratingLink(true);
    generateSession(token, botId)
      .then((response) => {
        setShareLink(response.data.link);
        toast.success("Share link generated successfully");
        setShowLink(true);
        setGeneratingLink(false);
      })
      .catch(() => {
        toast.error("Session could not be generated");
        setGeneratingLink(false);
      });
  };

  useEffect(() => {
    handleBotList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Modal Code Here */}
      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.onClose();
          handleBotList();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Bot</ModalHeader>
          <ModalCloseButton _focus={{ boxShadow: "none" }} />
          <ModalBody>
            <FormControl>
              <Text mb={2}>Bot Name</Text>
              <Flex>
                <Input
                  _focus={{ boxShadow: "none" }}
                  color={textColor}
                  bg={bgColor}
                  style={{ borderRadius: "50px", height: "50px" }}
                  mr={2}
                  type="text"
                  placeholder="Bot Name"
                  value={bottName}
                  onChange={(e) => setBotName(e.target.value)}
                />
              </Flex>
            </FormControl>
            <Box my={5} display={"none"}>
              <Grid h="200px" templateRows="repeat(2, 1fr)" templateColumns="repeat(4, 1fr)" gap={2}>
                <GridItem
                  colSpan={2}
                  bg={bgColor}
                  borderRadius={15}
                  cursor="pointer"
                  style={{
                    border: selectedBot === 1 ? "2px solid #805AD5" : "none",
                  }}
                  onClick={() => {
                    setSelectedBot(1);
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="center" height="100%" flexDirection="column" gap="2">
                    <Icon as={IoChatbubbleEllipsesOutline} w={8} h={8} />
                    <Text>Website</Text>
                  </Box>
                </GridItem>
                <GridItem
                  colSpan={2}
                  bg={bgColor}
                  borderRadius={15}
                  cursor="pointer"
                  style={{
                    border: selectedBot === 2 ? "2px solid #805AD5" : "none",
                  }}
                  onClick={() => {
                    setSelectedBot(2);
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="center" height="100%" flexDirection="column" gap="2">
                    <Icon as={RxDiscordLogo} w={8} h={8} />
                    <Text>Discord</Text>
                  </Box>
                </GridItem>
                <GridItem
                  colSpan={2}
                  bg={bgColor}
                  borderRadius={15}
                  cursor="pointer"
                  style={{
                    border: selectedBot === 3 ? "2px solid #805AD5" : "none",
                  }}
                  onClick={() => {
                    setSelectedBot(3);
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="center" height="100%" flexDirection="column" gap="2">
                    <Icon as={AiOutlineApi} w={8} h={8} />
                    <Text>Api</Text>
                  </Box>
                </GridItem>
                {/* <GridItem colSpan={2} bg={bgColor} borderRadius={15} cursor='not-allowed'>
                  <Box display='flex' alignItems='center' justifyContent='center' height='100%' flexDirection='column' gap='2'>
                    <Icon as={FaSlack} w={8} h={8} />
                    <Text>Slack</Text>
                  </Box>
                </GridItem> */}
              </Grid>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={modal.onClose}>
              Close
            </Button>
            <Button variant="brand" onClick={() => createBot()}>
              {loader ? <Spinner color="white" size="md" /> : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <LinkModal shareLink={shareLink} showLink={showLink} setShowLink={setShowLink} />
      {/* Drawer Code Here */}
      <Drawer
        size="xl"
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onClose();
          handleBotList();
        }}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ boxShadow: "none" }} />

          <DrawerHeader bg={bgColor} display="flex">
            <Text flexGrow={1}>{botName} Configration</Text>

            <Button mr={7} colorScheme="teal" variant="brand" width="170px" fontSize={"sm"} onClick={generateNewSession}>
              {generatingLink ? <Spinner /> : "Generate Share Link"}
            </Button>
          </DrawerHeader>

          <DrawerBody bg={bgColor}>
            <BotConfigrations />
          </DrawerBody>

          <DrawerFooter bg={bgColor}>
            {dbType === 1 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#03BE09",
                    marginRight: "10px",
                  }}
                ></div>
                <span>Connected with Pinecone DB</span>
              </div>
            ) : dbType === 2 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#03BE09",
                    marginRight: "10px",
                  }}
                ></div>
                <span>Connected with Aura DB</span>
              </div>
            ) : dbType === 3 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#03BE09",
                    marginRight: "10px",
                  }}
                ></div>
                <span>Connected with Neptune DB</span>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#03BE09",
                    marginRight: "10px",
                  }}
                ></div>
                <span>DB Connected</span>
              </div>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Main Content Here */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "5fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        <Flex flexDirection="column" gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Flex direction="column">
            <Flex
              mt="45px"
              mb="20px"
              w="100%"
              justifyContent="space-between"
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}
            >
              <Text color={textColor} fontSize="2xl" fontWeight="bolder">
                Your Bots
              </Text>
              <Button
                variant="brand"
                fontSize="sm"
                fontWeight="500"
                px="24px"
                py="5px"
                onClick={() => {
                  setBotName("");
                  setSelectedBot(0);
                  modal.onOpen();
                }}
              >
                Create New Bot
              </Button>
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
              {loading ? (
                <Flex
                  mt="45px"
                  mb="20px"
                  ml={6}
                  justifyContent="start"
                  direction={{ base: "column", md: "row" }}
                  align={{ base: "start", md: "center" }}
                >
                  <Spinner color="blue.500" size="md" mr={3} />
                  <Text color={textColor} fontSize="lg" ms="4px" fontWeight="normal">
                    Please wait...
                  </Text>
                </Flex>
              ) : bots.length > 0 ? (
                bots?.map((bot, index) => {
                  return (
                    <Bot
                      id={bot.botId}
                      key={index}
                      name={bot.name}
                      is_active={bot.is_active}
                      is_running={bot.is_running}
                      author={bot.tagline === null ? "Please set your bot tagline..." : bot.tagline}
                      currentbid={formatTimestamp(bot.created_at)}
                      fetchBots={() => {
                        handleBotList();
                      }}
                      //
                      onPress={() => {
                        updateBot(bot);
                        onOpen();
                      }}
                    />
                  );
                })
              ) : (
                <Text>
                  Create your bot now.{" "}
                  <span
                    onClick={() => {
                      setBotName("");
                      setSelectedBot(0);
                      modal.onOpen();
                    }}
                    style={{
                      textDecoration: "none",
                      color: "#007FC3",
                      cursor: "pointer",
                      fontWeight: "bold",
                      gap: "5px",
                    }}
                  >
                    Click Here
                  </span>
                </Text>
              )}
            </SimpleGrid>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
}
