import React from "react";
// import FixedPlugin from "../../components/fixedPlugin/FixedPlugin";

function DefaultAuth(props) {
  const { children } = props;
  // Chakra color mode
  return (
    
    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem" }}>
       {children}
      {/* <FixedPlugin /> */}
    </div>
  );
}
// PROPS

export default DefaultAuth;
