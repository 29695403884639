import React, { useState, useEffect, useRef } from "react";
import { useUserContext } from '../../../../contexts/UserContext';
import {
    Box,
    FormControl,
    Flex,
    Input,
    Button,
    Text,
    useColorModeValue,
    Icon,
    List,
    ListItem,
    useDisclosure
} from "@chakra-ui/react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton
} from '@chakra-ui/react'

import { IoMdAdd } from "react-icons/io";
import { Spinner } from '@chakra-ui/react';
import { IoIosLink } from "react-icons/io";
import { TiDeleteOutline } from "react-icons/ti";
import toast from 'react-hot-toast';
import { getBotUrls, addBotURL, deleteBotURL } from "../../../../Service";
import { useBotContext } from "../../../../contexts/BotContext";

const URLKnowledge = () => {
    const bgColor = useColorModeValue("#F4F7FD", "navy.900");
    const textColor = useColorModeValue("navy.700", "white");
    const [loading, setLoading] = useState(false); // eslint-disable-line no-unused-vars
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [url, setUrl] = useState('');
    const token = window.localStorage.getItem('user-token');
    const [urls, setUrls] = useState([]);
    const { botId } = useBotContext();
    const { updateTokens } = useUserContext();
    const [deleteThisURL, setDeleteThisURL] = useState(null);
    const cancelRef = useRef()

    const addURL = () => {
        setLoading(true);
        const payload = {
            bot_id: botId,
            url: url,
            google: false,
        }
        addBotURL(payload, token).then((response) => {
            toast.success("URL added successfully");
            updateTokens(response.data.tokens);
            setUrls(response.data.urls);
            setLoading(false);
            setUrl('');
        })
            .catch((error) => {
                if(error.response){
                    toast.error(error.response.data.message);
                }
                setLoading(false);
            });
    };

    const fetchURLS = () => {
        setLoading(true);
        getBotUrls(botId, token).then((response) => {
            setUrls(response.data.urls);
            setLoading(false);
        })
            .catch(() => {
                toast.error("Fetching urls failed");
                setLoading(false);
            });
    }

    const deleteTheURL = (urlID) => {
        setLoading(true);
        deleteBotURL(token, botId, urlID).then((response) => {
            toast.success("URL deleted successfully");
            setUrls(response.data.urls);
            setDeleteThisURL(null)
            setLoading(false);
        })
            .catch((error) => {
                if(error.response){
                    toast.error(error.response.data.message);
                }else{
                    toast.error("URL deletion failed");
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchURLS();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading ? <Box mt={5} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Spinner />
            <Text fontSize="sm" mt={3} fontWeight="normal" color={textColor}>
                Please Wait...
            </Text>
        </Box> : <Box my={3}>
            <Text fontSize="28px" fontWeight="bold" marginBottom="10px" color={textColor}>
                <Box display="flex" alignItems="center">
                    Add URL's
                </Box>
            </Text>
            <Text marginBottom="20px" color={textColor}>This information is stored in your database in the form of long-term memory.</Text>

            <FormControl>
                <Flex>
                    <Input
                        _focus={{ boxShadow: "none" }}
                        color={textColor}
                        bg={bgColor}
                        style={{ borderRadius: "50px", height: '50px' }}
                        mr={2}
                        type="text"
                        placeholder="https://"
                        value={url}
                        onChange={(e) => {
                            setUrl(e.target.value);
                        }}
                    />
                    <Button
                        fontSize='sm'
                        fontWeight='500'
                        variant="brand"
                        h='50'
                        mb='24px'
                        onClick={() => {
                            addURL();
                        }}
                    >
                        <Icon as={IoMdAdd} w={5} h={6} />
                    </Button>
                </Flex>
            </FormControl>
            <Box mt={3}>
                        <List spacing={3}>
                            {urls.map((url, index) => (
                                <ListItem key={index}>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <Icon as={IoIosLink} w={5} h={6} color='green' />
                                            {url.url}
                                        </Box>
                                        <Icon onClick={() => {
                                            setDeleteThisURL(url.url_id)
                                            onOpen()
                                        }
                                        } as={TiDeleteOutline} w={5} h={6} color='red' cursor='pointer' />
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
        </Box> }

        <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Delete URL</AlertDialogHeader>
                    <AlertDialogCloseButton _focus={{ boxShadow: "none" }} />
                    <AlertDialogBody>
                        Are you sure you want to delete?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => {
                            setDeleteThisURL(null)
                            onClose()
                        }} _focus={{ boxShadow: "none" }}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={() => {
                            deleteTheURL(deleteThisURL)
                            onClose();
                        }} _focus={{ boxShadow: "none" }}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default URLKnowledge;