import React, { useState, useEffect, useRef } from "react";
import { useUserContext } from "../../../../contexts/UserContext";
import {
  Box,
  Flex,
  Input,
  Button,
  Text,
  useColorModeValue,
  Icon,
  List,
  ListItem,
  useDisclosure,
  Link,
  FormLabel,
  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
import { AlertDialog, AlertDialogBody, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton } from "@chakra-ui/react";

import { Spinner } from "@chakra-ui/react";
import { TiDocumentText } from "react-icons/ti";
import { TiDeleteOutline } from "react-icons/ti";
import toast from "react-hot-toast";
import { addBotDocsURL, getBotDocsURL, deleteBotURL } from "../../../../Service";
import { useBotContext } from "../../../../contexts/BotContext";
import AlertBoxPopup from "./AlertBoxPopup";
import SpinningIconComponent from "./SpinningIconComponent.jsx";

const GoogleSuiteVersionTwo = () => {
  const bgColor = useColorModeValue("#F4F7FD", "navy.900");
  const textColor = useColorModeValue("navy.700", "white");
  const [loading, setLoading] = useState(false); // eslint-disable-line no-unused-vars
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modal, setModal] = useState(false);
  const [urls, setUrls] = useState([]);
  const token = window.localStorage.getItem("user-token");
  const [url, setUrl] = useState("");
  const [urlTitle, setUrlTitle] = useState("");
  const { botId } = useBotContext();
  const { updateTokens } = useUserContext();
  const [deleteThisURL, setDeleteThisURL] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const cancelRef = useRef();

  const onCloseModal = () => {
    setModal(false);
  };

  const onOpenModal = () => {
    setModal(true);
  };

  function convertToCsvExportUrl(sheetURL) {
    const csvExportPattern = /\/export\?format=csv&id=/;

    if (csvExportPattern.test(sheetURL)) {
      return sheetURL;
    }

    const idMatch = sheetURL.match(/[-\w]{25,}/);
    if (!idMatch) {
      throw new Error('Invalid Google Sheets URL');
    }
    const sheetId = idMatch[0];
    const gidMatch = sheetURL.match(/[#&]gid=(\d+)/);
    const gid = gidMatch ? gidMatch[1] : '0';

    return `https://docs.google.com/spreadsheets/d/${sheetId}/export?format=csv&gid=${gid}`;

  }

  function cleanGoogleDocUrl(url) {
    const editPattern = /\/edit.*$/;
    const cleanedUrl = url.replace(editPattern, "");
    return cleanedUrl;
  }

  const processURL = (url) => {
    if (url.startsWith("https://docs.google.com/spreadsheets/d/")) {
      return convertToCsvExportUrl(url);
    } else if (url.startsWith("https://docs.google.com/document/d/")) {
      return cleanGoogleDocUrl(url);
    } else {
      throw new Error("Invalid URL");
    }
  };

  const addURL = async (reload, loadedURL) => {
    try {
      setButtonLoading(true);
      if (url === "" && !reload) {
        toast.error("Please enter url");
        setUrl("");
        return;
      }

      const payload = {
        google: true,
        url_title: urlTitle,
        google_docs_link: processURL(loadedURL || url),
        reload: reload,
      };

      await addBotDocsURL(payload, token, botId)
        .then((response) => {
          "";
          toast.success(response.data.message);
          updateTokens(response.data.tokens);
          setUrls(response.data.urls);
          setUrl("");
          setUrlTitle("");
          setButtonLoading(false);
          onClose();
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          }
          setButtonLoading(false);
        });
    } catch (err) {
      toast.error(err.message);
      setButtonLoading(false);
    } finally {
      setButtonLoading(false);
    }
  };

  const fetchURLS = () => {
    setLoading(true);
    getBotDocsURL(botId, token)
      .then((response) => {
        setUrls(response.data.urls);
        updateTokens(response.data.tokens);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Fetching url failed");
        setLoading(false);
      });
  };

  const deleteTheURL = (urlID) => {
    setLoading(true);
    deleteBotURL(token, botId, urlID)
      .then((response) => {
        toast.success("URL deleted successfully");
        setUrls(response.data.urls);
        setDeleteThisURL(null);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("URL deletion failed");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchURLS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Box
          mt={5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Spinner />
          <Text fontSize="sm" mt={3} fontWeight="normal" color={textColor}>
            Please Wait...
          </Text>
        </Box>
      ) : (
        <Box>
          <Button
            fontSize="sm"
            fontWeight="500"
            h="50"
            w="200px"
            mt="5"
            variant="brand"
            onClick={() => {
              onOpen();
            }}
          >
            Connect Google Doc
          </Button>
          <Box mt={5}>
            <List spacing={3}>
              {urls.map((url, index) => (
                <ListItem key={index}>
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px", fontSize: "14px" }}>
                      <Icon as={TiDocumentText} w={5} h={6} color="blue" />
                      <Link target="__blank" href={url.url}>
                        {url.url_title}
                      </Link>
                    </Box>
                    <Flex gap="5px">
                      <SpinningIconComponent url={url} addURL={addURL} />
                      <Icon
                        onClick={() => {
                          setDeleteThisURL(url.url_id);
                          onOpenModal();
                        }}
                        as={TiDeleteOutline}
                        w={5}
                        h={6}
                        color="red"
                        cursor="pointer"
                      />
                    </Flex>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}

      <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent width="500px">
          <AlertDialogHeader>Connect to Google</AlertDialogHeader>
          <AlertDialogCloseButton _focus={{ boxShadow: "none" }} />
          <AlertDialogBody>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addURL(false, url);
              }}
            >
              <FormControl isRequired>
                <FormLabel>Title</FormLabel>
                <Input
                  maxLength={50}
                  bg={bgColor}
                  type="text"
                  required
                  placeholder="Provide a title"
                  value={urlTitle}
                  onChange={(e) => setUrlTitle(e.target.value)}
                />
                <Text fontSize="xs" textAlign={"end"}>{urlTitle.length}/50</Text>
                <FormLabel mt={3}>Link</FormLabel>
                <Input
                  bg={bgColor}
                  type="text"
                  required
                  placeholder="Paste your 'Share' link here"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
                <FormHelperText>We accept documents, spreadsheets, csv.</FormHelperText>
                <Button type="submit" mt={4} width={"150px"} h={10} variant="brand" fontWeight="500" fontSize="sm">
                  {buttonLoading ? <Spinner /> : "Connect"}
                </Button>
              </FormControl>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>

      <AlertBoxPopup
        deleteThisURL={deleteThisURL}
        deleteTheURL={deleteTheURL}
        cancelRef={cancelRef}
        setDeleteThisURL={setDeleteThisURL}
        isOpen={modal}
        onClose={onCloseModal}
      />
    </>
  );
};

export default GoogleSuiteVersionTwo;
