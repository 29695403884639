import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { login } from "../../../Service";
import Logo from '../../../assets/icons/Logo.svg';

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { Spinner } from '@chakra-ui/react'

// Custom components
import DefaultAuth from "../../../layouts/auth/Default";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import toast, { Toaster } from 'react-hot-toast';
import { forgot_password } from "../../../Service";

function SignIn() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const bgColor = useColorModeValue("#F4F7FD", "navy.900");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const [emailForgot, setEmailForgot] = useState('')
  const [emailLoading, setEmailLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(false)
  const history = useHistory()

  const handleLogin = () => {
    setLoading(true)
    login({
      username: email,
      password: password,
    }).then((response) => {
      const { token } = response.data;
      toast.success("Login Successful");
      setLoading(false)
      window.localStorage.setItem("user-token", token);
      history.push('/app/dashboard');
    })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message)
        }
        setLoading(false)
      })
  }

  const forgotPassword = () => {
    if(emailForgot === ''){
      toast.error("Please enter your email")
      return
    }

    setEmailLoading(true)
    forgot_password(emailForgot).then(response => {
      toast.success(response.data.message)
      setEmailLoading(false)
      onClose()
    }).catch((error) => {
      if(error.response){
        toast.error(error.response.data.message)
      }
      setEmailForgot('')
      setEmailLoading(false)
    })
  }

  useEffect(() => {
    const token = window.localStorage.getItem("user-token");
    if (token) {
      history.push('/app/dashboard')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => {
        setEmailForgot('')
        onClose()
      }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Forgot Password</ModalHeader>
            <ModalCloseButton _focus={{ boxShadow: "none" }} />
            <ModalBody>
            <FormControl>
              <Flex>
                <Input
                  _focus={{ boxShadow: "none" }}
                  color={textColor}
                  bg={bgColor}
                  style={{ borderRadius: "50px", height: '50px' }}
                  mr={2}
                  type="email"
                  placeholder="Email"
                  value={emailForgot}
                  onChange={(e) => setEmailForgot(e.target.value)}
                />
              </Flex>
            </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="brand" onClick={() => {
                forgotPassword()
              }}>
                {
                  emailLoading ? <Spinner color="white" /> : "Send Email"
                }
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <h1 style={{
          fontSize: '35px',
          fontWeight: 'bolder',
          textAlign: 'start',
          marginBottom: '20px',
        }}>
          <img src={Logo} alt="logo" style={{ height: "60px", marginRight: "10px" }} />
        {/* botworx<span style={{ color: "#7551FF" }}>.</span>io */}
        </h1>
      </div>
      <DefaultAuth>
        <Toaster position="top-right" reverseOrder={false}/>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign In
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>

            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='mail@botworx.com'
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={() => {
                      setShow(!show);
                    }}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                  <Checkbox
                    id='remember-login'
                    colorScheme='brandScheme'
                    me='10px'
                    onChange={(e) => setRemember(e.target.checked)}
                    value={remember}
                  />
                  <FormLabel
                    htmlFor='remember-login'
                    mb='0'
                    fontWeight='normal'
                    color={textColor}
                    fontSize='sm'>
                    Keep me logged in
                  </FormLabel>
                </FormControl>
                <div onClick={() => {
                  setEmailForgot('')
                  onOpen()
                }}>
                  <Text
                    _hover={{ cursor: "pointer" }}
                    color={textColorBrand}
                    fontSize='sm'
                    w='124px'
                    fontWeight='500'>
                    Forgot password?
                  </Text>
                </div>
              </Flex>
              <Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={() => handleLogin()}>
                {
                  loading ? <Spinner color="white" /> : "Sign In"
                }
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                Not registered yet?
                <NavLink to='/signup'>
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    Join the Beta
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default SignIn;
