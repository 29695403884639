import React from 'react';
import { FaCheck } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useHistory } from 'react-router-dom';

const Verified = () => {
    const history = useHistory()

  return (
    <div style={{ 
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
     }}>
        <FaCheck size={80} color='#3311DB' />
        <br />
        <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>
            Your account has been verified.
        </h1>
        <p>
            Thank you for verifying your account. You can now sign in to your account.
        </p>
        <button
            onClick={() => history.push('/signin')}
            style={{
                background: "#3311DB",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                marginTop: "20px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center"
            }}
        >
            <IoIosArrowRoundBack size={20} style={{ marginRight: "10px" }} />
            Go back to sign in
        </button>
    </div>
  )
}

export default Verified;