import useQuery from "./useQuery.jsx";
import { useEffect } from "react";
import { Chatbot } from "./Class.js";
import toast, { Toaster } from "react-hot-toast";

const PreviewPage = () => {
  const query = useQuery();
  const bot_id = query.get("bot_id");
  const api_key = query.get("api_key");
  const session_id = query.get("session_id");

  useEffect(() => {
    const chatbot = new Chatbot(bot_id, api_key, session_id);
    chatbot.start();
    setTimeout(() => {
      toast("Link is only valid for 30 mins", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }, 2000);
  }, []);

  return (
    <>
      <Toaster />
    </>
  );
};

export default PreviewPage;
