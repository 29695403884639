import { Box, Text } from "@chakra-ui/react";
import React from "react";

const Banner = ({ text }) => {
  let style = {
    background: "linear-gradient(0deg,rgba(49, 49, 177, 1) 0%,rgba(49, 49, 177, 1) 0%,rgba(49, 49, 177, 0.4) 100%)",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: "bold",
  };

  return (
    <Box
      bgImage={
        "https://images.unsplash.com/photo-1508389377389-b8221c0bcc9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
      }
      backdropBlur={0.4}
      height={"300px"}
      borderRadius="10px"
    >
      <div style={style}>
        <Text color="white" fontWeight="bold" textTransform="uppercase" fontSize="4xl">
          {text}
        </Text>
      </div>
    </Box>
  );
};

export default Banner;
