import React, { useEffect, useState } from "react";
import { useUserContext } from "../contexts/UserContext";
import { checkSubscription } from "../Service";
import { Spinner } from "@chakra-ui/react";
import Plans from "./app/Plans";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

/**
 * A component that guards routes by checking authentication status.
 * If the user is authenticated, it renders the children components.
 * If not authenticated, it redirects to the sign-in page.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children components to render if authenticated.
 * @returns {ReactNode} - The authentication guard component.
 */
const SubAuthGuard = ({ children }) => {
    const auth_token = window.localStorage.getItem("user-token");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { isSubscribed, updateIsSubscribed, updateTokens, updatePlanName, updatePlanAmount, updateStartDate, updateEndDate, updateStatus, updateIsAdmin, updateFounder, updateN_bots, updateFree, updatePId } = useUserContext();
    const { pathname } = useLocation();
    useEffect(() => {
        if (auth_token) {
            setIsLoading(true);
            
            checkSubscription(auth_token).then((response) => {
                    const data = response.data.plan;
                    const subscription = response.data.subscription;
                    updateIsAdmin(data.admin);
                    updateTokens(data.tokens);
                    updatePlanName(data.name);
                    updatePlanAmount(data.amount);
                    updateStartDate(subscription.start_date);
                    updateEndDate(subscription.end_date);
                    updateStatus(subscription.status);
                    setIsLoading(false);
                    setIsAuthenticated(true);
                    updateIsSubscribed(true);
                    updateFounder(data.founder);
                    updateFree(data.free);
                    updateN_bots(data.n_bots);
                    updatePId(data.pId);
                })
                .catch((error) => {
                    updateIsSubscribed(false);
                    setIsAuthenticated(false);
                    setIsLoading(false);
                })
        } else {
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth_token]);

    if (isLoading) {
        // Show loading state, you can customize this according to your needs
        return <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(255, 255, 255, 0.5)',
        }}>
            <Spinner variant='brand' />
        </div>;
    }

    if (!isAuthenticated) {
        if(!isSubscribed && pathname.includes('/app')){
            return <Plans />
        }
        return children;
    }

    return children;
};

export default SubAuthGuard;