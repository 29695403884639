import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, IconButton, Image, Input, InputGroup, InputRightElement, Spinner, Text } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useBotContext } from "../../../../contexts/BotContext";
import { uploadImages } from "../../../../Service";
import toast from "react-hot-toast";

const ImageUploader = ({ setCounter }) => {
  const fileInputRef = useRef();
  const token = window.localStorage.getItem("user-token");

  const { botId } = useBotContext();
  const [imageList, setImageList] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedExtensions = ["image/jpeg", "image/png", "image/heic"];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

    if (selectedFile) {
      if (!allowedExtensions.includes(selectedFile.type)) {
        alert("Only .jpg, .png, and .heic files are allowed");
        return;
      }
      if (selectedFile.size > maxSizeInBytes) {
        alert("File size should not exceed 5MB");
        return;
      }
      setImageList((prev) => [...prev, selectedFile]);
    }
  };

  const handleRemoveFile = (index) => {
    setImageList((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (imageList.length === 0) {
      toast.error("Please select atleast one image.");
      return;
    }

    setLoader(true);
    const formData = new FormData();
    imageList.forEach((image, index) => {
      formData.append(`image${index + 1}`, image);
    });

    await uploadImages(token, botId, formData)
      .then((response) => {
        toast.success(response.data.message);
        setImageList([]);
        setLoader(false);
        setCounter((prev) => prev + 1);
      })
      .catch((err) => {
        toast.error("Unable to upload Images.");
        setLoader(false);
      });
  };

  return (
    <>
      <Box my={3}>
        <InputGroup size="md" my={3}>
          <Input accept=".jpg,.jpeg,.png,.heic" type="file" ref={fileInputRef} display="none" onChange={handleFileChange} />
          <Input placeholder="Click here to choose file." value="" isReadOnly onClick={handleFileInputClick} />
          <InputRightElement width="7rem">
            {!loader ? (
              <Button h="1.75rem" size="sm" onClick={handleSubmit} ml={2}>
                Submit
              </Button>
            ) : (
              <Spinner size="xs" />
            )}
          </InputRightElement>
        </InputGroup>
        <Text fontSize={"xs"} ml={3}>Maximum file size: 5 MB.</Text>
        <Flex wrap="wrap" spacing={4} mt={4}>
          {imageList.map((file, index) => (
            <Box key={index} mr={4} mb={4} position={"relative"}>
              <IconButton
                position="absolute"
                top={2}
                right={2}
                size="sm"
                icon={<CloseIcon />}
                onClick={() => handleRemoveFile(index)}
                aria-label="Remove image"
              />
              <Image src={file instanceof File ? URL.createObjectURL(file) : file} alt={file.name} boxSize="150px" objectFit="contain" />
            </Box>
          ))}
        </Flex>
      </Box>
    </>
  );
};

export default ImageUploader;
