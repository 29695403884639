import { Box, useStyleConfig } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const fadeInOut = keyframes`
  0%, 100% { border-color: transparent; }
  50% { border-color: #22DD22; }
`;

function Card(props) {
  const { variant, is_running, children, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });
  const runningStyle = is_running
    ? {
        border: "2px solid green",
        animation: `${fadeInOut} 2s infinite`,
      }
    : {};

  return (
    <Box __css={{ ...styles, ...runningStyle }} {...rest} style={{ borderRadius: "0px" }}>
      {children}
    </Box>
  );
}

export default Card;
