import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text } from "@chakra-ui/react";

const OtherDocumentCard = (props) => {
  const { message } = props;

  const truncateFileName = (fileName, maxLength = 15) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    const name = lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;
    const extension = lastDotIndex !== -1 ? fileName.slice(lastDotIndex) : "";

    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...${extension}`;
    }
    return fileName;
  };

  return (
    <Box
      position="relative"
      bg="white"
      borderRadius="md"
      boxShadow="md"
      padding="10px"
      margin="8px auto" // Center the Box horizontally
      display="flex"
      alignItems="center"
      justifyContent="space-between" // Ensure content is spaced evenly
      marginLeft={message.isSent ? "auto" : "0"} // Align to the right for sent messages
      marginRight={message.isSent ? "0" : "auto"} // Align to the left for received messages
      transition="background 0.3s ease" // Add smooth transition effect for hover
      _hover={{ bg: "gray.100" }} // Light background on hover
    >
      <Text fontSize="md" fontWeight="bold" color="black" flex="1">
        {truncateFileName(message?.document_path.split("/").pop())}
      </Text>
      <Box display="flex" gap="2">
        <IconButton
          icon={<DownloadIcon color="black" />}
          onClick={() => {
            fetch(message.document_path)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = message?.document_path.split("/").pop();
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              })
              .catch(() => alert("Failed to download file."));
          }}
          aria-label="Download Document"
          size="sm" // Smaller button size
          bg="transparent"
          _hover={{ bg: "gray.200" }} // Light background on hover
        />
        <IconButton
          icon={<ViewIcon color="black" />}
          onClick={() => {
            window.open(message.document_path, "_blank");
          }}
          aria-label="Preview Document"
          size="sm" // Smaller button size
          bg="transparent"
          _hover={{ bg: "gray.200" }} // Light background on hover
        />
      </Box>
    </Box>
  );
};

export default OtherDocumentCard;
