import React, { useEffect, useState } from "react";
import { getUploadedImages, deleteUploadedImage } from "../../../../Service";
import { useBotContext } from "../../../../contexts/BotContext";
import { Box, Flex, IconButton, Image, Spinner } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import toast from "react-hot-toast";

const Gallery = ({ counter }) => {
  const [images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleter, setDeleter] = useState([]);

  const { botId } = useBotContext();
  const token = window.localStorage.getItem("user-token");

  useEffect(() => {
    async function fetchData() {
      setLoader(true);
      const response = await getUploadedImages(token, botId);
      setImages(response.data);
      setDeleter(Array.from({ length: response.data.length }, () => false));
      setLoader(false);
    }
    
    fetchData();
  }, [botId, token, counter]);

  const handleRemoveFile = (index) => {
    setDeleter((prev) => prev.map((_, i) => i === index));
    const imageId = images[index].imageID;
    deleteUploadedImage(token, imageId)
      .then((response) => {
        setImages(response.data);
        toast.success("Image deleted successfully");
        setDeleter(Array.from({ length: response.data.length }, () => false));
      })
      .catch((error) => {
        toast.error("Unable to delete image");
        setDeleter(Array.from({ length: images.length }, () => false));
      });
  };

  return (
    <>
      {loader ? (
        <Box my={10} display={"flex"}>
          <Spinner margin={"auto"} />
        </Box>
      ) : images.length ? (
        <Flex wrap="wrap" spacing={4} mt={4}>
          {images?.map((file, index) => (
            <Box key={index} mr={4} mb={4} position={"relative"}>
              {deleter[index] && <Spinner position="absolute" style={{ top: "50%", left: "50%", transform: "translate(-50% , -50%)" }} />}
              {!deleter[index] && (
                <IconButton
                  position="absolute"
                  top={2}
                  right={2}
                  size="sm"
                  icon={<DeleteIcon />}
                  onClick={() => handleRemoveFile(index)}
                  aria-label="Remove image"
                />
              )}
              <Image src={file?.image_url} opacity={deleter[index] ? 0.5 : 1} alt={file.name} boxSize="150px" objectFit="contain" />
            </Box>
          ))}
        </Flex>
      ) : (
        <Box>No image found</Box>
      )}
    </>
  );
};

export default Gallery;
