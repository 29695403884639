// Chakra imports
import {
  Button,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Switch
} from "@chakra-ui/react";
import { MdDeleteOutline } from "react-icons/md";
import { useRef, useState } from "react";
import { deleteTheBot } from "../../Service";
import { Spinner } from "@chakra-ui/react";

import toast from 'react-hot-toast';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure
} from '@chakra-ui/react'

// Custom components
import Card from "../card/Card";
// Assets
import React from "react";
import { updateBotStatus } from "../../Service";

export default function Bot(props) {
  const { name, author, currentbid, is_active } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [botStatus, setBotStatus] = useState(is_active)
  const cancelRef = useRef()
  const token = window.localStorage.getItem("user-token");

  const deleteBot = () => {
    const token = window.localStorage.getItem('user-token');
    setLoading(true);
    deleteTheBot(token, props.id)
      .then(() => {
        toast.success('Bot deleted successfully')
        props.fetchBots()
        setLoading(false);
        onClose()
      })
      .catch(() => {
        toast.error('Something went wrong')
        setLoading(false);
        onClose()
      });
  }

  const _updateBotStatus = (status, botId) => {
    setLoading2(true);
    updateBotStatus(token, botId, status).then((response) => {
        setBotStatus(response.data.status)
        setLoading2(false);
        props.fetchBots()
    })
        .catch((error) => {
            if (error.response) {
                toast.error(error.response.data.message);
            }
            setLoading2(false);
        })
}


  return (
    <>
      <Card p='20px' is_running={props.is_running}>
        <Flex h={'100%'} direction={{ base: "column" }} justify='center' position='relative'>
          <Icon as={MdDeleteOutline} position='absolute' onClick={() => onOpen()} top='0px' right='0px' cursor='pointer' color='red' />
          {loading2 ? <Spinner color="brand.500" size="md" position='absolute' top='0px' right='30px' /> : <Switch colorScheme='brand' isChecked={botStatus} position='absolute' top='0px' right='30px' onChange={(e) => {
              _updateBotStatus(e.target.checked, props.id)
          }} />}
          <Flex flexDirection='column' justify='space-between' h='100%'>
            <Flex
              justify='space-between'
              direction={{
                base: "row",
                md: "column",
                lg: "row",
                xl: "column",
                "2xl": "row",
              }}
              mb='auto'>
              <Flex direction='column'>
                <Text
                  color={textColor}
                  fontSize={{
                    base: "lg",
                    md: "sm",
                    lg: "md",
                    xl: "md",
                    "2xl": "md",
                    "3xl": "lg",
                  }}
                  mb='5px'
                  fontWeight='bold'
                  me='14px'>
                  {name}
                </Text>
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='400'
                  me='14px'>
                  {author}
                </Text>
              </Flex>
            </Flex>
            <Flex
              align='start'
              justify='space-between'
              direction={{
                base: "row",
                md: "column",
                lg: "row",
                xl: "column",
                "2xl": "row",
              }}
              mt='30px'>
              <Text mt={2} fontWeight='700' fontSize='sm' color={textColorBid}>
                Created at: {currentbid}
              </Text>
              <Button
                variant='brand'
                fontSize='sm'
                fontWeight='500'
                px='24px'
                py='5px' onClick={() => props.onPress()}>
                Open
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Delete Bot</AlertDialogHeader>
          <AlertDialogCloseButton _focus={{ boxShadow: "none" }} />
          <AlertDialogBody>
            Are you sure you want to delete?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={() => {
              onClose()
            }} _focus={{ boxShadow: "none" }}>
              No
            </Button>
            <Button colorScheme='red' ml={3} onClick={() => {
              deleteBot()
            }} _focus={{ boxShadow: "none" }}>
              {loading ? <Spinner color="white" size="md" /> : 'Yes'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
