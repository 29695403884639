import { useState } from "react";
import { Avatar, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import axios from "axios";
import { BASE_URL } from "../../Service";
import toast from "react-hot-toast";

// Custom Components
import Accordion from "../../components/Accordion";
import { ItemContent } from "../../components/menu/ItemContent";
import { SidebarResponsive } from "../../components/sidebar/Sidebar";
import { useUserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { logout } from "../../Service";
import { getCreditPackages } from "../../Service";
import { useDisclosure } from "@chakra-ui/react";

// Assets
import { MdNotificationsNone } from "react-icons/md";
import { RiRobot2Line } from "react-icons/ri";
import routes from "../../routes";
import { ThemeEditor } from "./ThemeEditor";
export default function HeaderLinks(props) {
  const { secondary } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue("14px 17px 40px 4px rgba(112, 144, 176, 0.18)", "14px 17px 40px 4px rgba(112, 144, 176, 0.06)");
  const { tokens } = useUserContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [creditPackages, setCreditPackages] = useState([]); // eslint-disable-line no-unused-vars
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clicked, setClicked] = useState();

  const getCreditPackage = () => {
    setLoading3(true);
    const token = window.localStorage.getItem("user-token");
    getCreditPackages(token)
      .then((res) => {
        setLoading3(false);
        setCreditPackages(res.data);
        onOpen();
      })
      .catch((error) => {
        setLoading3(false);
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleLogout = () => {
    const token = window.localStorage.getItem("user-token");
    setLoading(true);
    logout(token)
      .then(() => {
        setLoading(false);
        window.localStorage.removeItem("user-token");
        history.push("/signin");
      })
      .catch(() => {
        setLoading(false);
        window.localStorage.removeItem("user-token");
        history.push("/signin");
      });
  };

  const handleFormSubmit = async (stripePriceId, one_time, request_type, credits) => {
    setLoading2(true);
    // Fetch the user's authentication token (adjust this to your implementation)
    const auth_token = window.localStorage.getItem("user-token");
    const authToken = "Token " + auth_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/checkout`,
        {
          price_id: stripePriceId,
          is_one_time: one_time,
          request_type: request_type,
          credits: credits,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );

      setLoading2(false);
      toast.success("Checkout session created successfully.");
      window.location.href = response.data.url;
    } catch (error) {
      setLoading2(false);
      toast.error("Error creating checkout session.");
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w={"50%"} maxWidth={1000}>
          <ModalHeader>Buy Credits</ModalHeader>
          <ModalCloseButton _focus={{ boxShadow: "none" }} />
          <ModalBody >
            <Accordion
              creditPackage={creditPackages}
              clicked={clicked}
              setClicked={setClicked}
              loading2={loading2}
              handleFormSubmit={handleFormSubmit}
            />
            {/* {creditPackages.map((creditPackage, index) => (
							<Box key={index} background="#F4F7FD" width='100%' mt={5} p='20px'>
								<Text color={textColor} fontSize='xl' fontWeight='bolder'>
									{creditPackage.name}
								</Text>
								<Text color="grey" fontSize='lg' fontWeight='normal'>
									{creditPackage.credits.toLocaleString()} Credits
								</Text>
								<Text color="grey" fontSize='lg' fontWeight='bolder'>
									${creditPackage.amount.toLocaleString()}
								</Text>
								<Button
									fontSize='sm'
									fontWeight='500'
									height='50px'
									width="100%"
									mt={4}
									variant='brand'
									onClick={() => {
										handleFormSubmit(creditPackage.price_id, true, 'buy_credits', creditPackage.credits);
										setClicked(creditPackage.price_id);
									}}
								>
									{
										loading2 && clicked === creditPackage.price_id ? <Spinner color="white" size="md" /> : 'Buy Now'
									}
								</Button>
							</Box>
						))} */}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        w={{ sm: "100%", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        <Flex bg={ethBg} display={"flex"} borderRadius="30px" ms="auto" p="6px" align="center" me="6px">
          {loading3 ? (
            <Spinner size="sm" mr={2} ml={2} />
          ) : (
            <>
              <Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
                <Icon color={ethColor} w="20px" h="14px" as={RiRobot2Line} />
              </Flex>

              <Text w="max-content" color={ethColor} fontSize="sm" cursor="pointer" fontWeight="700" me="6px" onClick={() => getCreditPackage()}>
                {tokens?.toLocaleString()}
                <Text as="span" display={{ base: "none", md: "unset" }}>
                  {" "}
                  Credits
                </Text>
              </Text>
            </>
          )}
        </Flex>
        <SidebarResponsive routes={routes} />
        <Menu>
          <MenuButton p="0px">
            <Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: "30px", md: "unset" }}
            minW={{ base: "unset", md: "400px", xl: "450px" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex jusitfy="space-between" w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Notifications
              </Text>
              <Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
                {""}
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} px="0" borderRadius="8px" mb="10px">
                <ItemContent info="New bot added" aName="Alicia" tagline="New bot alexa have been created successfully" />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>

        <ThemeEditor navbarIcon={navbarIcon} />

        <Menu>
          <MenuButton p="0px">
            <Avatar _hover={{ cursor: "pointer" }} color="white" name="Bot Worx" bg="#11047A" size="sm" w="40px" h="40px" />
          </MenuButton>
          <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, Welcome
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem
                onClick={() => {
                  history.push("/app/dashboard");
                }}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Dashboard</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/app/account");
                }}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Profile</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/plans");
                }}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Plans</Text>
              </MenuItem>
              <MenuItem onClick={() => {}} _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
                <Text fontSize="sm">Support</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleLogout();
                }}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
              >
                {loading ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Spinner size="sm" />
                    <Text
                      fontSize={"md"}
                      color={textColor}
                      fontWeight="normal"
                      ps={{
                        sm: "10px",
                        xl: "16px",
                      }}
                      pt="5px"
                    >
                      Loging out...
                    </Text>
                  </div>
                ) : (
                  <Text fontSize="sm">Log out</Text>
                )}
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
