import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ListItem,
  UnorderedList,
  Text,
  Stack,
  Flex,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useUserContext } from "../../../../contexts/UserContext";

const PaymentModal = ({
  isOpen,
  onClose,
  handler,
  loader,
  handlerType,
  planName,
  planAmount,
  planCredits,
  planBots,
  currentPlan,
  proratedAmount,
  calculateProratedAmount,
}) => {
  const [preview, setPreview] = useState(false);
  const [previewLoader, setPreviewLoader] = useState(false);

  const dynamicModalData = {
    cancel: {
      key: "Cancel Subscription",
      title: "Are you sure you want to cancel your subscription?",
      buttonText: "Cancel plan",
      loadingText: "Cancelling...",
    },
    downgrade: {
      key: "Downgrade Subscription",
      title: "Are you sure you want to downgrade your subscription?",
      buttonText: "Downgrade plan",
      loadingText: "Downgrading...",
    },
    upgrade: {
      key: "Upgrade Subscription",
      title: "Are you sure you want to upgrade your subscription?",
      buttonText: "Upgrade plan",
      loadingText: "Creating checkout...",
    },
    buy: {
      key: "Buy Subscription",
      title: "Are you sure you want to buy this subscription?",
      buttonText: "Buy plan",
      loadingText: "Creating checkout...",
    },
  };
  const { endDate } = useUserContext();
  const getDeletionDate = () => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 90);
    let options = { year: "numeric", month: "long", day: "numeric" };
    let formattedDate = currentDate.toLocaleDateString(undefined, options);
    return formattedDate
  };
  
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {handlerType === "cancel" ? (
          // cancel plan
          <>
            <ModalHeader textAlign="center">{dynamicModalData[handlerType]["title"]}</ModalHeader>
            <ModalBody>
              <UnorderedList spacing={3}>
                <ListItem>Your bots will be deactivated.</ListItem>
                <ListItem>All bot memory will be destroyed.</ListItem>
                <ListItem>Your account will be deleted on {getDeletionDate()}</ListItem>
              </UnorderedList>
            </ModalBody>
          </>
        ) : handlerType === "downgrade" ? (
          // downgrade plan
          <>
            <ModalHeader textAlign="center">{dynamicModalData[handlerType]["title"]}</ModalHeader>
            <ModalBody>
              <Text textAlign="center">
                You are downgrading to <span style={{ fontWeight: "800" }}>{planName}</span> plan. Your billing amount from {endDate} would be{" "}
                <span style={{ fontWeight: "800" }}>${planAmount}/month.</span>
              </Text>
            </ModalBody>
          </>
        ) : handlerType === "upgrade" ? (
          // upgraade plan
          <>
            <ModalHeader textAlign="center">You are upgrading to {planName} plan.</ModalHeader>
            <ModalBody>
              <Text textAlign="center">Your new monthly bill will start on {endDate} and will be</Text>
              <Text textAlign="center" fontSize="3xl" mt={3} fontWeight="bold">
                ${planAmount}/month
              </Text>
              {preview ? (
                <Stack>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>New plan</Text>
                    <Text>${planAmount}</Text>
                  </Flex>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Previous plan</Text>
                    <Text>${parseFloat(currentPlan.amount).toFixed(0)}</Text>
                  </Flex>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Current due</Text>
                    <Text color="red.300">${planAmount - parseFloat(currentPlan.amount).toFixed(0)}</Text>
                  </Flex>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Balance for {Math.floor((new Date(endDate) - new Date()) / (1000 * 60 * 60 * 24))} days</Text>
                    <Text color="green.300">${(planAmount - parseFloat(currentPlan.amount).toFixed(0) - proratedAmount).toFixed(2)}</Text>
                  </Flex>
                  <hr />
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontWeight="bold">Amount Due</Text>
                    <Text color="red.300">${proratedAmount}</Text>
                  </Flex>
                </Stack>
              ) : (
                // Details about features on upgrading plan
                <>
                  <Text my={3} fontSize="xs">
                    Note: When you upgrade your plan, you will be required to pay an extra prorated amount. This amount is calculated based on the
                    time remaining in your current billing cycle and the difference in cost between your current plan and the new plan you are
                    upgrading to.
                  </Text>
                  <UnorderedList fontWeight={"bold"} color={"green.300"}>
                    <ListItem>{planCredits - currentPlan.credits} credits.</ListItem>
                    <ListItem>{planBots === 999 ? "Unlimited" : planBots} bots.</ListItem>
                    <ListItem>Increased discounts on credit purchase.</ListItem>
                  </UnorderedList>
                </>
              )}
            </ModalBody>
          </>
        ) : (
          // New purchase
          <>
            <ModalHeader textAlign="center">{dynamicModalData[handlerType]["title"]}</ModalHeader>
            <ModalBody>
              <Stack>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>{planName} x 1</Text>
                  <Text>${planAmount}</Text>
                </Flex>
                <hr />
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontWeight={"bold"}>Amount Due</Text>
                  <Text color="red.300">${planAmount}</Text>
                </Flex>
              </Stack>
            </ModalBody>
          </>
        )}

        <ModalFooter>
          {!preview ? (
            <Button variant="brand" colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          ) : (
            <Button variant="brand" colorScheme="blue" mr={3} onClick={() => setPreview(false)}>
              Back
            </Button>
          )}
          {!preview && handlerType === "upgrade" ? (
            <Button
              isLoading={previewLoader}
              loadingText={"Fetching..."}
              spinnerPlacement="end"
              rightIcon={<ArrowForwardIcon />}
              onClick={async () => {
                setPreviewLoader(true);
                await calculateProratedAmount().then(() => {
                  setPreviewLoader(false);
                  setPreview(true);
                });
              }}
              variant="outline"
            >
              Bill summary
            </Button>
          ) : (
            <Button
              isLoading={loader}
              loadingText={dynamicModalData[handlerType]["loadingText"]}
              spinnerPlacement="end"
              rightIcon={<ArrowForwardIcon />}
              onClick={() => {
                handler();
              }}
              variant="outline"
            >
              {dynamicModalData[handlerType]["buttonText"]}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
