import React from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
} from "@chakra-ui/react";

const AlertBoxPopup = ({ deleteThisURL, deleteTheURL, cancelRef, setDeleteThisURL, isOpen, onClose }) => {
  return (
    <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Delete URL</AlertDialogHeader>
        <AlertDialogCloseButton _focus={{ boxShadow: "none" }} />
        <AlertDialogBody>Are you sure you want to delete?</AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              setDeleteThisURL(null);
              onClose();
            }}
            _focus={{ boxShadow: "none" }}
          >
            No
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={() => {
              deleteTheURL(deleteThisURL);
              onClose();
            }}
            _focus={{ boxShadow: "none" }}
          >
            Yes
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertBoxPopup;
