import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Text,
    Button,
} from '@chakra-ui/react';
import { useBotContext } from '../../../../contexts/BotContext';
import { useUserContext } from '../../../../contexts/UserContext';
import { useColorModeValue } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import { CiFileOn } from "react-icons/ci";
import { TiDeleteOutline } from "react-icons/ti";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure
} from '@chakra-ui/react'

import {
    List,
    ListItem,
    Icon
} from '@chakra-ui/react'

import { fileUpload, getBotFiles, deleteBotFile } from '../../../../Service';
import toast from 'react-hot-toast';

function getFileNameFromURL(url) {
    // Split the URL by '/' to get an array of parts
    const parts = url.split('/');

    // Get the last part of the URL, which should be the file name
    const fileName = parts[parts.length - 1];

    return fileName;
}

function FilesKnowledge() {
    const token = window.localStorage.getItem('user-token');
    const [fileLoading, setFileLoading] = useState(false); // eslint-disable-line no-unused-vars
    const { updateTokens } = useUserContext();
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [deleteThisFile, setDeleteThisFile] = useState(null); // eslint-disable-line no-unused-vars
    const bgColor = useColorModeValue("#F4F7FD", "navy.900");
    const textColor = useColorModeValue("navy.700", "white");
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    const { botId } = useBotContext();
    const fileRef = useRef(null);


    const uploadFile = () => {
        setFileLoading(true);
        const formData = new FormData();
        formData.append('bot_id', botId);
        formData.append('file', file);
        fileUpload(formData, token).then((response) => {
            toast.success("File uploaded successfully");
            updateTokens(response.data.tokens);
            setFiles(response.data.files);
            setFile(null)
            setFileLoading(false);
        })
            .catch((error) => {
                if(error.response){
                    toast.error(error.response.data.message);
                }
                setFile(null);
                setFileLoading(false);
            });
    };

    const fetchFiles = () => {
        setFileLoading(true);
        getBotFiles(botId, token).then((response) => {
            setFiles(response.data.files);
            setFileLoading(false);
        })
            .catch(() => {
                toast.error("Fetching files failed");
                setFileLoading(false);
            });
    }

    const deleteTheFile = (fileId) => {
        setFileLoading(true);
        deleteBotFile(token, botId, fileId).then((response) => {
            toast.success("File deleted successfully");
            setFiles(response.data.files);
            setDeleteThisFile(null)
            setFileLoading(false);
        })
            .catch(() => {
                toast.error("File deletion failed");
                setFileLoading(false);
            });
    }

    useEffect(() => {
        fetchFiles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId]);

    return (
        <>
            {fileLoading ? <Box mt={5} style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <Spinner />
                <Text fontSize="sm" mt={3} fontWeight="normal" color={textColor}>
                    Please Wait...
                </Text>
            </Box> :
                <Box my={3}>
                    <Text fontSize="28px" fontWeight="bold" marginBottom="10px" color={textColor}>
                        <Box display="flex" alignItems="center">
                            Upload Files<Text marginLeft="5px" marginTop="7px" fontSize="sm" fontWeight="normal">(Max 5Mb file)</Text>
                        </Box>
                    </Text>
                    <Text marginBottom="20px" color={textColor}>This information is stored in your database in the form of long-term memory.</Text>

                    <Box
                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }} mt={3}>
                        <Box
                            background={bgColor}
                            style={{
                                background: { bgColor },
                                width: '50%',
                                padding: '14px 20px',
                                borderRadius: '10px',
                                border: '1px solid rgba(0,0,0,0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                fileRef.current.click();
                            }}
                        >
                            <input
                                type="file"
                                ref={fileRef}
                                style={{ display: 'none' }}
                                accept=".txt, .pdf, .docx, .md, .json, .csv"
                                onChange={() => {
                                    setFile(fileRef.current.files[0]);
                                }}
                            />
                            {file ? (
                                <Text fontSize="16px" display="flex" color={textColor}>
                                    {file.name}
                                </Text>
                            ) : (
                                <Text color={textColor} fontSize="15px">Upload your *.txt, *.pdf, *.docx, *.md, *.json, *.csv</Text>
                            )}
                        </Box>
                        {file ? (
                            <Button
                                fontSize='sm'
                                fontWeight='500'
                                w='20%'
                                variant='brand'
                                h='50'
                                onClick={() => uploadFile()}>
                                Upload
                            </Button>
                        ) : null}
                    </Box>

                    <Box mt={10}>
                        <List spacing={3}>
                            {files.map((file, index) => (
                                <ListItem key={index}>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <Icon as={CiFileOn} w={5} h={6} color='green' />
                                            {getFileNameFromURL(file.file)}
                                        </Box>
                                        <Icon onClick={() => {
                                            setDeleteThisFile(file.file_id)
                                            onOpen()
                                        }
                                        } as={TiDeleteOutline} w={5} h={6} color='red' cursor='pointer' />
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>}

            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Delete File</AlertDialogHeader>
                    <AlertDialogCloseButton _focus={{ boxShadow: "none" }} />
                    <AlertDialogBody>
                        Are you sure you want to delete?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => {
                            setDeleteThisFile(null)
                            onClose()
                        }} _focus={{ boxShadow: "none" }}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={() => {
                            deleteTheFile(deleteThisFile)
                            onClose();
                        }} _focus={{ boxShadow: "none" }}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
}

export default FilesKnowledge;
